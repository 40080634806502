<template>
    <a-card>
        <a-page-header
            title="更多视频信息"
        />
        <div class="content">
            <div class="row"><span class="title">视频ID：</span><span class="txt">{{ dataForm.videoId || '-' }}</span></div>
            <div class="row"><span class="title">视频宽度：</span><span class="txt">{{ dataForm.width || '-' }}</span></div>
            <div class="row"><span class="title">视频高度：</span><span class="txt">{{ dataForm.height || '-' }}</span></div>
            <div class="row"><span class="title">视频时长：</span><span class="txt">{{ dataForm.duration || '-' }}</span></div>
            <div class="row"><span class="title">视频URL：</span><span class="txt">{{ dataForm.adVideoUrl || '-' }}</span></div>
            <div class="row"><span class="title">关联项目ID：</span><span class="txt">{{ dataForm.projectId || '-' }}</span></div>
            <div class="row"><span class="title">关联项目：</span><span class="txt">{{ dataForm.projectName || '-' }}</span></div>
            <div class="row"><span class="title">广告主编码：</span><span class="txt">{{ dataForm.advertiserCode || '-' }}</span></div>
            <div class="row"><span class="title">投放用途：</span><span class="txt">{{ dataForm.deliveryPurpose == 1 ? '销售线索收集' : dataForm.deliveryPurpose == 2 ? '直播预热' : '-' }}</span></div>
            <div class="row"><span class="title">导拍模板：</span><span class="txt">{{ dataForm.guideTemplateName || '-' }}</span></div>
            <div class="row"><span class="title">导拍模板ID：</span><span class="txt">{{ dataForm.guideTemplateId || '-' }}</span></div>
            <div class="row"><span class="title">视频上传方式：</span><span class="txt">{{ dataForm.videoSource || '-' }}</span></div>
            <div class="row"><span class="title">上传人皮皮慧投账号：</span><span class="txt">{{ dataForm.creator || '-' }}</span></div>
            <div class="row"><span class="title">上传人风火轮账号：</span><span class="txt">{{ dataForm.fhlAccount || '-' }}</span></div>

            <div class="row"><span class="title">巨量广告视频ID：</span><span class="txt">{{ dataForm.adPlatformType == 1 ? dataForm.adVideoId : '-' }}</span></div>
            <div class="row"><span class="title">巨量广告素材ID：</span><span class="txt">{{ dataForm.adPlatformType == 1 ? dataForm.adMaterialId : '-' }}</span></div>
            <div class="row"><span class="title">巨量广告上传时间：</span><span class="txt">{{ dataForm.adPlatformType == 1 ?dataForm.uploadTime : '-' }}</span></div>
            <div class="row"><span class="title">磁力智投视频ID：</span><span class="txt">{{ dataForm.adPlatformType == 2 ? dataForm.adVideoId : '-' }}</span></div>
            <div class="row"><span class="title">磁力智投素材ID：</span><span class="txt">{{ dataForm.adPlatformType == 2 ? dataForm.adMaterialId : '-' }}</span></div>
            <div class="row"><span class="title">磁力智投上传时间：</span><span class="txt">{{ dataForm.adPlatformType == 2 ? dataForm.uploadTime : '-' }}</span></div>
            <div class="row"><span class="title">平台账套：</span><span class="txt">{{ dataForm.adPlatformAccountName || '-' }}</span></div>
            <div class="row"><span class="title">视频适用范围：</span><span class="txt">{{ dataForm.videoScope == 1 ? '车系' : dataForm.videoScope == 2 ? '品牌' : '-' }}</span></div>
            <div class="row"><span class="title">视频上传失败原因（广告平台）：</span><span class="txt">{{ dataForm.uploadFailReason || '-' }}</span></div>
            <div class="row"><span class="title">是否系统赠送</span><span class="txt">{{ dataForm.isGift == 1 ? '是' : '否' }}</span></div>
        </div>
        <div style="margin: 30px 90px;">
            <base-button
                :type="'primary'"
                :title="'返回'"
                @onClickBtn="handleBack"
            ></base-button>
        </div>
    </a-card>
</template>

<script>
export default {
    data() {
        return {
            id: this.$route.query.id,
            dataForm: {}
        }
    },
    created() {
        this.getDeliveryVideoDetail()
    },
    methods: {
        getDeliveryVideoDetail() {
            this.$api.core.materialManage.getDeliveryVideoDetail( this.id ).then(res => {
                if(res.code == 200) {
                    this.dataForm = res.data
                } else {
                    console.error(`获取详情失败，${res}`)
                }
            })
        },
        handleBack() {
            this.$router.push({
                path: '/castVideoManage'
            })
        },
    },
}
</script>

<style scoped lang="less">
/deep/ .ant-page-header {
    padding-left: 0;
    padding-right: 0;
}
.row {
    display: flex;
    margin: 20px;
    line-height: 1.5;
    .title {
        width: 150px;
        font-weight: bold;
        text-align: right;
    }
    .txt {
        flex: 1;
        margin-left: 10px;
        word-break: break-word;
    }
}
</style>